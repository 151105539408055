.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-modal);

  height: 100%;

  &.hidden {
    display: none;
  }

  &.needBorder {
    .footer {
      padding: var(--space-l) var(--space-xxl);
    }

    .head {
      padding: var(--space-l) var(--space-xxl);
    }

    .containerContent {
      padding-top: 0;
    }
  }

  .enterActive {
    .backdrop {
      background: rgba(0, 0, 0, 0.3);
    }

    .wrapperContent {
      transform: translate3d(0, 0, 0);
    }
  }

  .enterDone {
    .backdrop {
      background: rgba(0, 0, 0, 0.3);
    }

    .wrapperContent {
      transform: translate3d(0, 0, 0);
    }
  }
}

.wrapper {
  position: relative;

  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background: rgba(0, 0, 0, 0);

  transition: background-color 0.4s ease-in-out;
}

.wrapperContent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  background-color: var(--color-white);
  transform: translate3d(100%, 0, 0);

  transition: transform 0.4s ease-in-out;
}

.footer {
  padding: var(--space-l) var(--space-xxl) var(--space-xxl);

  border-top: 1px solid transparent;

  transition: border-color 0.2s ease-in-out;

  &.bordered {
    border-color: var(--color-medium-gray);
  }
}

.header {
  flex-shrink: 0;
  padding: 0 var(--space-xxl);
  overflow: hidden;
}

.wrapperPreloader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.head {
  position: relative;
  z-index: 10;

  display: flex;

  /* flex-start need for correct close-icon position about multiline title */
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--space-xxl) var(--space-xxl) 0;

  border-bottom: 1px solid transparent;

  transition: border-color 0.2s ease-in-out;

  &.bordered {
    border-color: var(--color-medium-gray);
  }
}

.titleWrapper {
  display: flex;
}

.title {
  margin: 0;

  font-weight: 500;
  font-size: var(--size-xl);
  font-family: var(--font-hero);
  line-height: 1.5;
}

.iconClose {
  flex-shrink: 0;
  margin-top: 2px;
  margin-left: var(--space-s);
}

.containerContent {
  position: relative;
  z-index: 10;

  flex-grow: 1;
  height: fit-content;
  padding: var(--space-xxl) var(--space-xxl) 0;
  overflow: hidden;

  background: var(--color-white);
}

.scroller {
  position: relative;
  z-index: 10;

  flex-grow: 1;
}

@media (--mobile) {
  .modal {
    &.viewDefault {
      .wrapperContent {
        max-height: calc(100% - 79px);
      }

      .wrapper {
        flex-direction: column;
        justify-content: space-between;

        &:before {
          height: 79px;

          content: '';
        }
      }
    }

    &.viewFullscreen {
      .wrapperContent {
        height: 100%;
      }
    }

    &.needBorder {
      .head {
        padding: var(--space-n) var(--space-s);
      }

      .footer {
        padding: var(--space-n) var(--space-s);
      }

      .containerContent {
        padding-top: 0;
      }
    }

    .enterActive {
      .wrapperContent {
        transform: translate3d(0, 0, 0);
      }
    }

    .enterDone {
      .wrapperContent {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .wrapper {
    align-items: flex-end;
  }

  .wrapperContent {
    position: static;

    max-width: 100%;

    transform: translate3d(0, 100%, 0);
  }

  .head {
    align-items: flex-start;
    padding: var(--space-m) var(--space-s) 0;
  }

  .footer {
    padding: var(--space-l) var(--space-s) var(--space-l);
  }

  .header {
    padding: 0 var(--space-s);
  }

  .containerContent {
    box-sizing: border-box;
    height: 100%;
    padding: var(--space-l) var(--space-s) 0;
    overflow-y: auto;
  }

  .title {
    font-size: var(--size-l);
    line-height: 1.5;
  }
}

@media (--mobile-s) {
  .titleWrapper {
    flex-direction: column;
    justify-content: flex-start;
  }
}
