.wrapperContent {
  max-width: 522px;
}

.group {
  margin-bottom: var(--space-xxl);
}

@media (--mobile) {
  .group:last-child {
    margin-bottom: var(--space-l);
  }
}
